import { GenderEnum } from "../enums/GenderEnum";
import { EmploymentTypeEnum } from "../enums/EmploymentTypeEnum";
import { ParticipantStatusEnum } from "../enums/ParticipantStatusEnum";
import { Country } from "./Country";

export type ParticipantSummary = {
  [k: string]:
    | number
    | string
    | GenderEnum
    | Date
    | EmploymentTypeEnum
    | undefined
    | boolean
    | Country;
  id: number;
  participantNumber?: string;
  gender: GenderEnum;
  initials?: string;
  firstName?: string;
  lastName?: string;
  prefix?: string;
  memberSince: Date;
  dateOfBirth: Date;
  preferredLanguage?: string;
  email?: string;
  emailContact?: string;
  employmentType: EmploymentTypeEnum;
  sector?: string;
  phone1?: string;
  phone2?: string;
  city?: string;
  country?: Country;
  houseNumber: number;
  houseNumberAddition?: string;
  postalCode?: string;
  street?: string;
  participantStatus: ParticipantStatusEnum;
  isEmployed: boolean;
};

export const defaultParticipantSummary: ParticipantSummary = {
  id: 0,
  gender: GenderEnum.Other,
  memberSince: new Date(),
  dateOfBirth: new Date(),
  employmentType: EmploymentTypeEnum.BusinessOwner,
  houseNumber: 0,
  participantStatus: ParticipantStatusEnum.Active,
  isEmployed: false,
};
