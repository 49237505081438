import { ReactNode } from "react";
import styles from "./Link.module.scss";
import { ColorEnum } from "../../enums/ColorEnum";

type ExternalLinkProps = {
  href: string;
  children?: ReactNode;
  color?: ColorEnum;
  underline?: boolean;
};

export const ExternalLink = ({
  children,
  href,
  color,
  underline = true,
}: ExternalLinkProps) => {
  let colorClass;
  let underlineClass;

  if (color == ColorEnum.Black) {
    colorClass = styles.Black;
  }
  if (underline) {
    underlineClass = styles.Underline;
  }

  return (
    <a
      className={`${underlineClass} ${colorClass}`}
      href={href}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  );
};
